import React, { FC } from 'react';
import ActionDropdown from 'components/Shared/ActionDropdown/ActionDropdown';
import { ButtonCellOptions, CellProps } from '../types';
import calculateClass from './Helpers/calculateClass';

const ActionDropdownCell = (id: string, options: ButtonCellOptions): FC<CellProps> => {
    const ActionDropdownComponent = ({ idKey, item, header, index, cellEventCallback }: CellProps) => {
        const buttons = options.buttons.map((b, i) => {
            const textWithoutSpace = b.text.split(' ').join('-');
            return (
                (!b.show || b.show(item)) && (
                    <button
                        key={i}
                        title={b.title}
                        id={`${textWithoutSpace}-${b.id}${index}`}
                        role={textWithoutSpace}
                        data-testid={`${textWithoutSpace}-${b.id}${index}`}
                        className={`btn btn-sm ${b.class}`}
                        onClick={() =>
                            cellEventCallback({
                                id: item[idKey] as string,
                                actionId: b.id,
                                index,
                                item,
                            })
                        }
                        disabled={b.disabled && b.disabled(item)}>
                        {b.component ? b.component(item) : b.text && <span className="me-2">{b.text}</span>}
                    </button>
                )
            );
        });

        return (
            <td headers={header.id} className={calculateClass(options, item)}>
                <ActionDropdown id={`${id}${index}`} actions={buttons} />
            </td>
        );
    };

    return ActionDropdownComponent;
};

export default ActionDropdownCell;
