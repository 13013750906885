import React, { FC, useState, useRef, useEffect } from 'react';
import './ActionDropdown.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { IActionDropdownProps } from '../types';

const ActionDropdown: FC<IActionDropdownProps> = ({ id, actions }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [dropdownPosition, setDropdownPosition] = useState({ left: 0, bottom: 0 });
    const ellipsisRef = useRef<HTMLSpanElement>(null);
    const dropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (isHovered && ellipsisRef.current && dropdownRef.current) {
            const ellipsisRect = ellipsisRef.current.getBoundingClientRect();
            const dropdownRect = dropdownRef.current.getBoundingClientRect();
            const screenWidth = window.innerWidth;
            const screenHeight = window.innerHeight;
            const ellipsisWidth = ellipsisRect.right - ellipsisRect.left;

            let newLeft: number;
            let newBottom: number;

            // Horizontal positioning
            if (ellipsisRect.left + dropdownRect.width > screenWidth) {
                newLeft = -dropdownRect.width + ellipsisWidth;
            } else {
                newLeft = 0;
            }

            // Vertical positioning
            if (ellipsisRect.bottom + dropdownRect.height > screenHeight) {
                newBottom = dropdownRect.height + ellipsisRect.height;
            } else {
                newBottom = 0;
            }

            setDropdownPosition({ left: newLeft, bottom: newBottom });
        }
    }, [isHovered]);

    return (
        <span
            className="ellipsis-button"
            onClick={e => {
                e.stopPropagation();
            }}
            ref={ellipsisRef}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            data-testid={id}
            id={id}>
            <FontAwesomeIcon icon={faEllipsis as IconProp} color="#195283" size="sm" />
            {isHovered && actions && (
                <span
                    className="ellipsis-options actions popover"
                    style={{
                        left: `${dropdownPosition.left}px`,
                        bottom: `${dropdownPosition.bottom}px`,
                    }}
                    ref={dropdownRef}>
                    {actions}
                </span>
            )}
        </span>
    );
};

export default ActionDropdown;
